$base-theme-path: '/sites/maccosmetics/themes/mac_base/';
$netstorage-font-path: '/_fonts/maccosmetics/';

$text-direction: ltr !default;
$rdirection: right !default;
$ldirection: left !default;

// Creative refresh 2021
$cr21: false !default;
// Temp config to toggle diff stayles
$cr21-images: true !default;

// Hide reserve widget for launch
$hide-reserve-spp: false !default;
$hide-rating-count--spp: false !default;

// cr21_mpp styles
$product-brief-2-rows-cta: false !default;
$product__price_ppu: false !default;
// Power Reviews "translations"
$reviews_helpful_yes: 'Yes' !default;
$reviews_helpful_no: 'No' !default;

// Creative refresh 2021 for SPP
$cr21_spp: true !default;

// Creative refresh 2021 for MPP.
$cr21_mpp: true !default;

// Gems
@import 'compass';
@import 'compass/support';
@import 'animation/core';
@import 'toolkit';
@import 'breakpoint';
@import '30-vendor/singularitygs';
@import 'overrides/overrides';
@import 'overrides';
@import 'global/styles';
