// Set all the colors for a link with one mixin call.
// Order of arguments is:
//
// 1. normal
// 2. hover
// 3. active
// 4. visited
// 5. focus
//
// Those states not specified will inherit.
// Mixin to an anchor link like so:
//     a
//       +link-colors(#00c, #0cc, #c0c, #ccc, #cc0)

@mixin link-colors($normal, $hover: false, $active: false, $visited: false, $focus: false) {
  color: $normal;
  &:not(.mantle-cta--custom-textcolor) {
    @if $visited {
      &:visited {
        color: $visited;
      }
    }
    @if $focus {
      &:focus {
        color: $focus;
      }
    }
    @if $hover {
      &:hover {
        color: $hover;
      }
    }
    @if $active {
      &:active {
        color: $active;
      }
    }
  }
}
